import React from "react";
import logo from "../../logo.png";
import iihtBanner from "../../iihtBanner.jpg";

const InquiryMain = ({ setInquiryTab }) => {
  return (
    <div className='grid grid-cols-12 gap-5'>
      <div className='col-span-6'>
        <img src={iihtBanner} alt='' className='max-h-screen' />
      </div>
      <div className='col-span-6 flex flex-col gap-5'>
        <div className=''>
          <img
            src={logo}
            alt=''
            height={80}
            width={200}
            className='block mx-auto'
          />
        </div>
        <div className='flex flex-col flex-grow justify-center gap-10'>
          <div>
            <h3 className='text-center text-4xl font-semibold text-deep-purple-400'>
              Welcome to IIHT Surat.
            </h3>
            <p className='text-center text-lg font-semibold text-deep-purple-400'>
              Please complete the inquiry form to continue.
            </p>
          </div>
          <div>
            <form
              onSubmit={() => {
                setInquiryTab("InquiryMobile");
              }}
              className='w-full'
            >
              <div className='mt-4 mb-2 sm:mb-4'>
                <button
                  name='newInquiry'
                  type='submit'
                  className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none'
                >
                  New Inquiry
                </button>
              </div>
            </form>
            <form
              onSubmit={() => {
                setInquiryTab("InquiryPassword");
              }}
              className='w-full'
            >
              <div className='mt-4 mb-2 sm:mb-4'>
                <button
                  name='viewInquiry'
                  type='submit'
                  className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-green-500 hover:bg-green-700 focus:shadow-outline focus:outline-none'
                >
                  View Inquiry
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryMain;
