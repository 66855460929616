import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import logo from "../../logo.png";
import iihtBanner from "../../iihtBanner.jpg";

const required = "This field is required";
const maxLength = "Your input exceed maximum length";
const pattern = "Please enter a valid ";

const errorMessage = (error) => {
  return <span className='text-red-500 text-xs italic'>{error}</span>;
};
const validMessage = (msg) => {
  return <span className='text-green-500 text-xs italic'>{msg}</span>;
};
const InquiryMobile = ({ setInquiryTab, setMobileNo, setOtp }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({
    mode: "all",
  });
  const onSubmit = async (data) => {
    await axios({
      headers: {},
      url: "https://apiiiht.iihtsrt.com/public/lead/checkphone",
      method: "post",
      data: { phone: "91" + data.Mobile },
    }).then((res) => {
      console.log(res);
      if (res.data.error === false) {
        setMobileNo(data.Mobile);
        setOtp(res.data.otp);
        setInquiryTab("InquiryOTP");
      } else {
        setInquiryTab("InquiryMobileError");
      }
    });
  };
  return (
    <div className='grid grid-cols-12 gap-5'>
      <div className='col-span-6'>
        <img src={iihtBanner} alt='' className='max-h-screen' />
      </div>
      <div className='col-span-6 flex flex-col gap-5'>
        <div className=''>
          <img
            src={logo}
            alt=''
            height={80}
            width={200}
            className='block mx-auto'
          />
        </div>
        <div className='flex flex-col flex-grow justify-center gap-10'>
          <div>
            <h2 className='text-center text-3xl font-semibold my-3'>
              Please Enter Mobile No.
            </h2>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor='' className='inline-block mb-1 font-medium '>
                Contact
              </label>
              <div className=''>
                <div>
                  <input
                    type='tel'
                    placeholder='Mobile'
                    className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
                    {...register("Mobile", {
                      required: true,
                      pattern: /^[6-9]\d{9}$/i,
                    })}
                  />
                  <p>
                    {errors.Mobile &&
                      errors.Mobile.type === "required" &&
                      errorMessage(required)}
                    {errors.Mobile &&
                      errors.Mobile.type === "pattern" &&
                      errorMessage(pattern + "Mobile")}
                    {!errors.Mobile &&
                      touchedFields.Mobile &&
                      validMessage("Thank You")}
                    &nbsp;
                  </p>
                </div>
              </div>
              <div className='mt-4 mb-2 sm:mb-4'>
                <button
                  type='submit'
                  className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none'
                >
                  Submit
                </button>
              </div>
            </form>
            <form
              onSubmit={() => {
                setInquiryTab("InquiryMain");
              }}
            >
              <div className='mt-4 mb-2 sm:mb-4'>
                <button
                  type='submit'
                  className='inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none'
                >
                  Exit{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryMobile;
