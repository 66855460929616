import React from "react";

const getCourseName = function (inquiry, courses) {
  return courses.find(function (course) {
    return course.course_id == inquiry.course_id;
  })?.course_name;
};

const InquirySingle = ({ setInquiryTab, singleInquiryData, courses }) => {
  return (
    <>
      <form
        onSubmit={() => {
          setInquiryTab("InquiryList");
        }}
      >
        <div className='mt-4 mb-2 sm:mb-4'>
          <button
            name='newInquiry'
            type='submit'
            className='inline-flex items-center justify-center w-auto h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none'
          >
            Go Back
          </button>
        </div>
      </form>
      <table className='text-left table-fixed'>
        <tbody className='font-medium text-xl leading-loose'>
          {singleInquiryData && singleInquiryData != "" && (
            <>
              <tr className='border-b border-slate-300'>
                <th>Full Name</th>
                <td>{singleInquiryData.full_name}</td>
                <th>Course Inquired</th>
                <td>{singleInquiryData.course_name}</td>
              </tr>
              <tr className='border-b border-slate-300'>
                <th>Father's Name</th>
                <td>{singleInquiryData.father_name}</td>
                <th>Course Selected</th>
                <td>
                  {courses &&
                    singleInquiryData.course_id &&
                    getCourseName(singleInquiryData, courses)}
                </td>
              </tr>
              <tr className='border-b border-slate-300'>
                <th>Date Of Birth</th>
                <td>{singleInquiryData.dob}</td>
                <th>Inquiry Date</th>
                <td>{singleInquiryData.enquiry_date}</td>
              </tr>
              <tr className='border-b border-slate-300'>
                <th>Gender</th>
                <td>{singleInquiryData.gender}</td>
                <th>Branch</th>
                <td>{singleInquiryData.branch}</td>
              </tr>

              <tr className='border-b border-slate-300'>
                <th>Email</th>
                <td>{singleInquiryData.email}</td>
                <th>Type</th>
                <td>
                  {singleInquiryData.duplicate == "0" ? (
                    <span className='my-3 w-auto px-2 py-1 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-md bg-light-green-700 mx-2'>
                      fresh
                    </span>
                  ) : (
                    <span className='my-3 w-auto px-2 py-1 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-md bg-orange-600 mx-2'>
                      repeated
                    </span>
                  )}
                </td>
              </tr>
              <tr className='border-b border-slate-300'>
                <th>Mobile Number</th>
                <td>{singleInquiryData.mob_no}</td>
                <th>Status</th>
                <td>
                  {singleInquiryData.enquiry_code == null
                    ? "Pending"
                    : "Completed"}
                </td>
              </tr>
              <tr className='border-b border-slate-300'>
                <th>Guardian Number</th>
                <td>{singleInquiryData.guardian_no}</td>
              </tr>
              <tr className='border-b border-slate-300'>
                <th>Address</th>
                <td colSpan={3}>{singleInquiryData.address}</td>
              </tr>
              <tr className='border-b border-slate-300'>
                <th>Source</th>
                <td>
                  {singleInquiryData.source}{" "}
                  {singleInquiryData.referal_name &&
                    "(" + singleInquiryData.referal_name + ")"}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </>
  );
};

export default InquirySingle;
