import Inquiry from "./Components/InquiryForm/Inquiry";

function App() {
  return (
    <>
      <Inquiry />
    </>
  );
}

export default App;
