import React, { useEffect } from "react";

const InquiryThankyou = ({ setInquiryTab }) => {
  useEffect(() => {
    setTimeout(() => {
      setInquiryTab("InquiryMain");
    }, 3000);
  }, []);
  return (
    <div className='max-w-xl sm:mx-auto lg:max-w-2xl'>
      <div className='flex flex-col sm:text-center sm:mb-0 h-full'>
        <div className='max-w-xl m-auto sm:text-center lg:max-w-2xl'>
          <h2 className='max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-deep-purple-400 sm:text-4xl md:mx-auto'>
            Thank You For Choosing IIHT.{" "}
          </h2>
          <p className='text-base text-indigo-100 md:text-lg'>
            We are more than glad to be part of your bright future.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InquiryThankyou;
