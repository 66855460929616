import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";

const required = "This field is required";
const maxLength = "Your input exceed maximum length";
const pattern = "Please enter a valid ";

const errorMessage = (error) => {
  return <span className='text-red-500 text-xs italic'>{error}</span>;
};
const validMessage = (msg) => {
  return <span className='text-green-500 text-xs italic'>{msg}</span>;
};

const InquiryUpdateForm = ({
  setInquiryTab,
  inquiryToEdit,
  sources,
  courses,
}) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
  } = useForm({
    mode: "all",
    defaultValues: inquiryToEdit,
  });
  const watchSource = watch("source");
  const onSubmit = async (data) => {
    await axios({
      headers: {},
      url: "https://apiiiht.iihtsrt.com/public/inquiry/update",
      method: "post",
      data: data,
    }).then((res) => {
      setInquiryTab("InquiryList");
    });
  };

  const [options, setOptions] = useState([]);

  const handleSelectChange = (selectedOption) => {
    setValue("course_id", selectedOption.value); // Update the form value
  };

  useEffect(() => {
    if (courses) {
      setOptions(
        courses.map((course) => {
          return { value: course.course_id, label: course.course_name };
        })
      );
    }
  }, [courses]);

  useEffect(() => {
    inquiryToEdit && sources && setValue("source", inquiryToEdit.source);
    inquiryToEdit &&
      inquiryToEdit.course_id == null &&
      setValue("course_id", "");
  }, [inquiryToEdit, sources]);
  return (
    <>
      <form
        onSubmit={() => {
          setInquiryTab("InquiryList");
        }}
      >
        <div className='mt-4 mb-2 sm:mb-4'>
          <button
            name='newInquiry'
            type='submit'
            className='inline-flex items-center justify-center w-auto h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none'
          >
            Go Back
          </button>
        </div>
      </form>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type='hidden'
          value={inquiryToEdit.branch}
          {...register("branch")}
        />
        <input type='hidden' value={inquiryToEdit.eid} {...register("eid")} />
        <input
          type='hidden'
          value={inquiryToEdit.enquiry_date}
          {...register("enquiry_date")}
        />
        <div className='grid lg:grid-cols-3 lg:gap-4'>
          <div className='col-span-2'>
            <label htmlFor='' className='inline-block mb-1 font-medium '>
              Full Name
            </label>
            <input
              type='text'
              placeholder='First and Last Name'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("full_name", {
                required: true,
                pattern: /^[a-zA-Z ]{1,20}$/i,
              })}
            />
            <p>
              {errors.full_name &&
                errors.full_name.type === "required" &&
                errorMessage(required)}
              {errors.full_name &&
                errors.full_name.type === "pattern" &&
                errorMessage(pattern + "Full Name")}
              {!errors.full_name &&
                touchedFields.full_name &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
          <div>
            <label htmlFor='' className='inline-block mb-1 font-medium '>
              Father's Name
            </label>
            <input
              type='text'
              placeholder="Father's Name"
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("father_name", {
                required: true,
                pattern: /^[a-zA-Z ]{1,20}$/i,
              })}
            />
            <p>
              {errors.father_name &&
                errors.father_name.type === "required" &&
                errorMessage(required)}
              {errors.father_name &&
                errors.father_name.type === "pattern" &&
                errorMessage(pattern + "Father's Name")}
              {!errors.father_name &&
                touchedFields.father_name &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
        </div>
        <div className='grid lg:grid-cols-3 lg:gap-4'>
          <div>
            <label htmlFor='' className='inline-block mb-1 font-medium '>
              Date Of Birth
            </label>
            <input
              type='date'
              placeholder='Date Of Birth'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("dob", { required: true })}
            />
            <p>
              {errors.dob &&
                errors.dob.type === "required" &&
                errorMessage(required)}
              {!errors.dob && touchedFields.dob && validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
          <div>
            <label htmlFor='' className='inline-block mb-1 font-medium '>
              Gender
            </label>
            <div className='flex-grow flex gap-4 w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'>
              <div className='flex items-center'>
                <input
                  id='male'
                  {...register("gender", { required: true })}
                  type='radio'
                  className='h-4 w-4 border-gray-300 text-deep-purple-400 focus:border-deep-purple-400'
                  value={"male"}
                />
                <label
                  htmlFor='male'
                  className='ml-3 block text-sm font-medium text-gray-700'
                >
                  Male
                </label>
              </div>
              <div className='flex items-center'>
                <input
                  id='female'
                  {...register("gender", { required: true })}
                  type='radio'
                  className='form-radio h-4 w-4 border-gray-300 text-deep-purple-400 focus:ring-deep-purple-400'
                  value={"female"}
                />
                <label
                  htmlFor='female'
                  className='ml-3 block text-sm font-medium text-gray-700'
                >
                  Female
                </label>
              </div>
            </div>
          </div>
          <div>
            <label htmlFor='' className='inline-block mb-1 font-medium '>
              Email
            </label>
            <input
              type='email'
              placeholder='Email'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("email", {
                required: true,
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/i,
              })}
            />
            <p>
              {errors.email &&
                errors.email.type === "required" &&
                errorMessage(required)}
              {errors.email &&
                errors.email.type === "pattern" &&
                errorMessage(pattern + "Email")}
              {!errors.email &&
                touchedFields.email &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
        </div>
        <div className='grid lg:grid-cols-2 lg:gap-4'>
          <div>
            <label htmlFor='' className='inline-block mb-1 font-medium '>
              Course Inquired
            </label>
            <input
              type='text'
              placeholder='Course'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("course_name", {
                required: true,
                pattern: /^[a-zA-Z \-+./0-9]{1,50}$/i,
              })}
            />
            <p>
              {errors.course_name &&
                errors.course_name.type === "required" &&
                errorMessage(required)}
              {errors.course_name &&
                errors.course_name.type === "pattern" &&
                errorMessage(pattern + "Course")}
              {!errors.course_name &&
                touchedFields.course_name &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
          <div>
            <label htmlFor='' className='inline-block mb-1 font-medium '>
              Course Selected
            </label>
            <Select
              options={options}
              styles={{
                control: (base) => ({
                  ...base,
                  height: "inherit",
                }),
                menu: (base) => ({
                  ...base,
                  borderRadius: "8px",
                  overflow: "hidden",
                  boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.5)",
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "white",
                  padding: "10px",
                  ":hover": {
                    backgroundColor: "rgb(41 70 149)",
                    color: "white",
                  },
                }),
              }}
              unstyled
              className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'"
              name='course_id'
              {...register("course_id", { required: true })}
              onChange={handleSelectChange}
            />
            <p>
              {errors.course_id &&
                errors.course_id.type === "required" &&
                errorMessage(required)}
              {errors.course_id &&
                errors.course_id.type === "pattern" &&
                errorMessage(pattern + "Course")}
              {!errors.course_id &&
                touchedFields.course_id &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
        </div>
        <label htmlFor='' className='inline-block mb-1 font-medium '>
          Address
        </label>
        <div>
          <textarea
            type='text'
            placeholder='Area'
            className='flex-grow w-full px-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
            {...register("address", { required: true })}
            rows='3'
          ></textarea>
          <p>
            {errors.address &&
              errors.address.type === "required" &&
              errorMessage(required)}
            {errors.address &&
              errors.address.type === "pattern" &&
              errorMessage(pattern + "Area")}
            {!errors.address &&
              touchedFields.address &&
              validMessage("Thank You")}
            &nbsp;
          </p>
        </div>
        <label htmlFor='' className='inline-block mb-1 font-medium '>
          Contact
        </label>
        <div className='grid lg:grid-cols-2 lg:gap-4'>
          <div>
            <input
              type='tel'
              placeholder='Mobile'
              className='flex-grow w-full h-12 px-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("mob_no", {
                required: true,
                pattern: /^[6-9]\d{9}$/i,
              })}
            />
            <p>
              {errors.mob_no &&
                errors.mob_no.type === "required" &&
                errorMessage(required)}
              {errors.mob_no &&
                errors.mob_no.type === "pattern" &&
                errorMessage(pattern + "Mobile")}
              {!errors.mob_no &&
                touchedFields.mob_no &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
          <div>
            <input
              type='tel'
              placeholder='Father/ Guardian Mobile'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("guardian_no", {
                required: true,
                pattern: /^[6-9]\d{9}$/i,
              })}
            />
            <p>
              {errors.guardian_no &&
                errors.guardian_no.type === "required" &&
                errorMessage(required)}
              {errors.guardian_no &&
                errors.guardian_no.type === "pattern" &&
                errorMessage(pattern + "Father Guardian Mobile")}
              {!errors.guardian_no &&
                touchedFields.guardian_no &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
        </div>
        <div>
          <label htmlFor='' className='inline-block mb-1 font-medium '>
            How did you get to know about IIHT?
          </label>
          <select
            className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'"
            {...register("source", { required: true })}
          >
            {sources &&
              sources.length > 0 &&
              sources.map((source, sourceIndex) => {
                return (
                  <option key={sourceIndex} value={source.source_name}>
                    {source.source_name}
                  </option>
                );
              })}
          </select>
          <p>
            {errors.source &&
              errors.source.type === "required" &&
              errorMessage(required)}
            {errors.source &&
              errors.source.type === "pattern" &&
              errorMessage(pattern + "Source")}
            {!errors.source &&
              touchedFields.source &&
              validMessage("Thank You")}
            &nbsp;
          </p>
          {watchSource == "REFERAL" && (
            <div>
              <input
                type='text'
                placeholder='Referal Name'
                className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
                {...register("referal_name", { required: true })}
              />
              <p>
                {errors.referal_name &&
                  errors.referal_name.type === "required" &&
                  errorMessage(required)}
                {errors.referal_name &&
                  errors.referal_name.type === "pattern" &&
                  errorMessage(pattern + "Referal Name")}
                {!errors.referal_name &&
                  touchedFields.referal_name &&
                  validMessage("Thank You")}
                &nbsp;
              </p>
            </div>
          )}
        </div>

        <div className='mt-4 mb-2 sm:mb-4'>
          <button
            type='submit'
            className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none'
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default InquiryUpdateForm;
