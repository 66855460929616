import React from "react";

const InquiryAlreadyExist = ({ setInquiryTab }) => {
  return (
    <div className='max-w-xl sm:mx-auto lg:max-w-2xl'>
      <div className='flex flex-col sm:text-center sm:mb-0 h-full'>
        <div className='max-w-xl m-auto sm:text-center lg:max-w-2xl'>
          <h2 className='max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-deep-purple-400 sm:text-4xl md:mx-auto'>
            You Have Already Registed.{" "}
          </h2>
          <p className='text-base text-indigo-100 md:text-lg'>
            You can veiw or edit your details.
          </p>
          <div className='mt-4 mb-2 sm:mb-4'>
            <form
              onSubmit={() => {
                setInquiryTab("InquiryForm");
              }}
            >
              <button
                type='submit'
                className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none'
              >
                Review Details
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryAlreadyExist;
