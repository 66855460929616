import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

const InquiryList = ({
  setInquiryTab,
  userToken,
  setSingleInquiryData,
  setInquiryToEdit,
  courses,
}) => {
  const getCourseName = function (inquiry) {
    if (inquiry.course_id == null) {
      return inquiry.course_name;
    }
    return courses.find(function (course) {
      return course.course_id == inquiry.course_id;
    })?.course_name;
  };

  const columns = [
    {
      name: "Name",
      maxWidth: "20%",
      selector: (row) => (
        <div className='text-base font-medium'>{row.full_name}</div>
      ),
      sortable: true,
    },
    {
      name: "Mobile",
      maxWidth: "max-content",
      selector: (row) => (
        <div className='text-base font-medium'>{row.mob_no}</div>
      ),
      sortable: true,
    },
    {
      name: "Course",
      maxWidth: "20%",
      selector: (row) => (
        <div className='text-base font-medium'>{getCourseName(row)}</div>
      ),
      sortable: true,
    },
    {
      name: "Date Of Inquiry",
      maxWidth: "20%",
      selector: (row) => (
        <div className='text-base font-medium'>{row.enquiry_date}</div>
      ),
      sortable: true,
    },
    {
      name: "Type",
      maxWidth: "15%",
      allowOverflow: true,
      selector: (row) => {
        return row.duplicate == "0" ? (
          <span className='my-3 w-auto px-2 py-1 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-md bg-light-green-700 mx-2'>
            fresh
          </span>
        ) : (
          <span className='my-3 w-auto px-2 py-1 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-md bg-orange-600 mx-2'>
            repeated
          </span>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      maxWidth: "15%",
      selector: (inquiry) => {
        return (
          <div className='flex'>
            <form
              onSubmit={() => {
                setSingleInquiryData(inquiry);
                setInquiryTab("InquirySingle");
              }}
              className='col-span-1'
            >
              <button
                title='view'
                className='my-3 w-auto px-2 py-2 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-orange-400 hover:bg-deep-orange-accent-200 focus:shadow-outline focus:outline-none mx-2'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                  />
                </svg>
              </button>
            </form>
            {inquiry.enquiry_code == null && (
              <form
                className='col-span-1'
                onSubmit={() => {
                  setSingleInquiryData(inquiry);
                  setInquiryToEdit(inquiry);
                  setInquiryTab("InquiryUpdateForm");
                }}
              >
                <button
                  title='edit'
                  className='my-3 w-auto px-2 py-2 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none mx-2'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                    />
                  </svg>
                </button>
              </form>
            )}
          </div>
        );
      },
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "700",
        lineHeight: "2.5rem",
        fontSize: "16px",
      },
    },
  };

  const [inquiries, setInquiries] = useState();

  useEffect(() => {
    axios({
      headers: {},
      url: "https://apiiiht.iihtsrt.com/public/inquiry/all",
      method: "post",
      data: userToken,
    }).then((res) => {
      setInquiries(res.data.data);
    });
  }, []);

  const paginationOptions = {
    rowsPerPageText: "Inquiries per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
    selectAllRowsItemSelectable: false,
  };
  return (
    <>
      <form
        onSubmit={() => {
          setInquiryTab("InquiryMain");
        }}
      >
        <div className='mt-4 mb-2 sm:mb-4'>
          <button
            name='newInquiry'
            type='submit'
            className='inline-flex items-center justify-center w-auto h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none'
          >
            Go Back
          </button>
        </div>
      </form>
      {courses && (
        <DataTable
          className={"text-center border shadow"}
          columns={columns}
          data={inquiries}
          pagination
          paginationComponentOptions={paginationOptions}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          paginationPerPage={10}
          highlightOnHover
          customStyles={customStyles}
          noDataComponent='No data available'
        />
      )}
    </>
  );
};

export default InquiryList;
