import React, { useEffect, useState } from "react";
import InquiryAlreadyExist from "./InquiryAlreadyExist";
import InquiryForm from "./InquiryForm";
import InquiryList from "./InquiryList";
import InquiryMain from "./InquiryMain";
import InquiryMobile from "./InquiryMobile";
import InquiryMobileError from "./InquiryMobileError";
import InquiryOTP from "./InquiryOTP";
import InquiryPassword from "./InquiryPassword";
import InquirySingle from "./InquirySingle";
import InquiryThankyou from "./InquiryThankyou";
import InquiryUpdateForm from "./InquiryUpdateForm";
import axios from "axios";

const Inquiry = () => {
  const [inquiryTab, setInquiryTab] = useState("InquiryMain");
  const [mobileNo, setMobileNo] = useState("");
  const [otp, setOtp] = useState("");
  const [userToken, setUserToken] = useState("");
  const [singleInquiryData, setSingleInquiryData] = useState("");
  const [inquiryToEdit, setInquiryToEdit] = useState("");
  const [sources, setSources] = useState();
  const [courses, setCourses] = useState();

  useEffect(() => {
    axios
      .get("https://apiiiht.iihtsrt.com/public/source/all")
      .then((response) => {
        setSources(response.data.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://apiiiht.iihtsrt.com/public/course/all")
      .then((response) => {
        setCourses(response.data.message);
      });
  }, []);

  return (
    <div className='px-4 flex flex-col min-h-screen justify-center mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-4 lg:px-8'>
      {inquiryTab == "InquiryMain" && (
        <InquiryMain setInquiryTab={setInquiryTab} />
      )}
      {inquiryTab == "InquiryPassword" && (
        <InquiryPassword
          setInquiryTab={setInquiryTab}
          setUserToken={setUserToken}
        />
      )}
      {inquiryTab == "InquiryList" && (
        <InquiryList
          setInquiryTab={setInquiryTab}
          userToken={userToken}
          setSingleInquiryData={setSingleInquiryData}
          setInquiryToEdit={setInquiryToEdit}
          courses={courses}
        />
      )}
      {inquiryTab == "InquiryMobile" && (
        <InquiryMobile
          setInquiryTab={setInquiryTab}
          setMobileNo={setMobileNo}
          setOtp={setOtp}
        />
      )}
      {inquiryTab == "InquiryOTP" && (
        <InquiryOTP
          setInquiryTab={setInquiryTab}
          otp={otp}
          setOtp={setOtp}
          mobileNo={mobileNo}
        />
      )}
      {inquiryTab == "InquiryAlreadyExist" && (
        <InquiryAlreadyExist setInquiryTab={setInquiryTab} />
      )}
      {inquiryTab == "InquiryMobileError" && (
        <InquiryMobileError setInquiryTab={setInquiryTab} />
      )}
      {inquiryTab == "InquiryForm" && (
        <InquiryForm
          setInquiryTab={setInquiryTab}
          mobileNo={mobileNo}
          sources={sources}
        />
      )}
      {inquiryTab == "InquiryThankyou" && (
        <InquiryThankyou setInquiryTab={setInquiryTab} />
      )}
      {inquiryTab == "InquirySingle" && (
        <InquirySingle
          setInquiryTab={setInquiryTab}
          singleInquiryData={singleInquiryData}
          setInquiryToEdit={setInquiryToEdit}
          courses={courses}
        />
      )}
      {inquiryTab == "InquiryUpdateForm" && (
        <InquiryUpdateForm
          setInquiryTab={setInquiryTab}
          inquiryToEdit={inquiryToEdit}
          sources={sources}
          courses={courses}
        />
      )}
    </div>
  );
};

export default Inquiry;
